import React from 'react';

import GlobalStyle from '../../styles/GlobalStyles'

import SEO from '../../components/seo'
import Nav from '../../components/nav'
import {CaseStudy, UIDesign, PromptPlayground, InteractionDesign } from '../../components/design';
import Footer from '../../components/footer';

const IndexPage = () => (
  <>
    <GlobalStyle />
    {/* eslint-disable-next-line */}
    <SEO title="Jonathan Hoy - Product Designer" />
    <header>
      <Nav />
    </header>
    <main>
      <CaseStudy showLink={false} />
      <UIDesign />
      <PromptPlayground shortList={true} />
      <InteractionDesign />
    </main>
    <footer>
      <Footer />
    </footer>
  </>
)

export default IndexPage